import { createAsyncThunk } from '@reduxjs/toolkit';
import { setLoading } from '../global';

import { bearerAuth } from '../auth';
import axios from 'axios';


export const issueVc = createAsyncThunk(
        'issueVc/issue', ({jwtToken, templateId, callbackUrl}: {jwtToken: string | undefined, templateId: string, callbackUrl: string}, thunkAPI) => {
            thunkAPI.dispatch(setLoading(true));
            const config = {
                headers: {'Authorization': bearerAuth(jwtToken)}
            };

            return axios.post('/manage/vc/' + templateId + "?callback=" + callbackUrl, {}, config)
                .then(response => {
                    console.log('QQQ response: ', response);
                    return response.data
                })
                .finally(() => {
                    thunkAPI.dispatch(setLoading(false));
                });
        },
);
