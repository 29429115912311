import { useKeycloak } from '@react-keycloak/web';
import React, { FC, useEffect, useState } from 'react';
import { useAppDispatch } from '../../state/hooks';
import { useSelector } from 'react-redux';
import { Button, Card, CardContent, CardHeader, Grid, TextField } from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { demoCredentialSelector, getDemoCredential } from '../../state';

export const SelectWallet: FC = () => {
    const dispatch = useAppDispatch();
    const {keycloak, initialized} = useKeycloak();
    const [searchParams] = useSearchParams();
    const [walletUrl, setWalletUrl] = useState<string>('');
    const templateId = searchParams.get('id');
    let navigate = useNavigate();
    const demoCredential = useSelector(demoCredentialSelector);

    console.log('QQQ keycloak.token: ', keycloak.token);
    useEffect(() => {
        if (templateId !== null) {
            dispatch(getDemoCredential({jwtToken: keycloak.token!, templateId: templateId}));
        }
    }, [keycloak.token, templateId]);

    useEffect(() => {
        setWalletUrl((demoCredential.singleItem?.companyWalletUrl === undefined) ? '' : demoCredential.singleItem?.companyWalletUrl);
    }, [demoCredential.singleItem?.companyWalletUrl]);

    function selectWallet(walletlUrl: string) {
        navigate('/issue?id=' + templateId + '&callback=' + walletlUrl);
    }

    return (
        <div>
            <Card variant="outlined">
                <CardHeader title='Select your wallet'/>
                <CardContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Grid container spacing={2} sx={{alignItems: 'center'}}>
                                <Grid item xs={7}>
                                    <TextField variant='outlined' placeholder="Specify the Wallet URL" fullWidth margin="dense" type="url" value={walletUrl} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        setWalletUrl(event.target.value);
                                    }}></TextField>
                                </Grid>
                                <Grid item xs={3}>
                                    <Button variant='outlined' onClick={() => selectWallet(walletUrl)}>Select</Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </div>
    );

};
