import { createSlice } from '@reduxjs/toolkit';
import { defaultGenericState, GenericState } from '../model';
import { addGenericStateSingleBuilders } from '../slice';
import { issueVc } from './IssueVcApi';


export interface IssueVc {
    oidcUrl: string;
}


export interface IssueVcState extends GenericState<IssueVc> {
}

export const issueVcSlice = createSlice({
    name: 'issvueVc',
    initialState: defaultGenericState,
    reducers: {},
    extraReducers: builder => {
        addGenericStateSingleBuilders(builder, issueVc);
    },
});
