import { Avatar, Box, Link, ListItemIcon, Menu, MenuItem, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useKeycloak } from '@react-keycloak/web';
import { Edit, Logout } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { EuropeLogo, Logo } from '../atoms';
import PersonIcon from '@mui/icons-material/Person';

interface Props {
}


export const Header: React.FC<Props> = (props) => {
    let {keycloak} = useKeycloak();
    let navigate = useNavigate();

    const [userName, setUserName] = useState<string>(' ');

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    function logoff() {
        keycloak.logout();
    }

    useEffect(() => {
        keycloak.loadUserInfo().then(userInfo => {
            // @ts-ignore
            setUserName(userInfo.name);
        });
    }, [keycloak]);


    function editProfile() {
        navigate('/company/edit');
    }

    return (

        <Box sx={{}}>
            <Box sx={{display: 'flex', justifyContent: 'center', height: '110px'}}>
                <Logo height={79}/>
            </Box>
            <Box sx={{display: 'flex', justifyContent: 'space-between', marginLeft: '80px', marginRight: '80px'}}>
                <Box sx={{display: 'flex', alignItems: 'center'}}>
                    <EuropeLogo height={55} width={55}/>
                    <Typography sx={{fontSize: '24px', fontFamily: 'inter', lineHeight: 1, color: '#313A89'}}>The Dutch Government</Typography>
                </Box>
                <Box sx={{display: 'flex', alignItems: 'center'}}>
                    <a href="/" style={{textDecoration: 'none', color: 'unset'}}><Typography sx={{paddingLeft: '16px', fontFamily: 'Roboto'}}>Home</Typography></a>
                    <a href="/" style={{textDecoration: 'none', color: 'unset'}}><Typography sx={{paddingLeft: '16px', fontFamily: 'Roboto'}}>Security</Typography></a>
                    <a href="/" style={{textDecoration: 'none', color: 'unset'}}><Typography sx={{paddingLeft: '16px', fontFamily: 'Roboto'}}>Government</Typography></a>
                    <a href="/" style={{textDecoration: 'none', color: 'unset'}}><Typography sx={{paddingLeft: '16px', fontFamily: 'Roboto'}}>Help & Contact</Typography></a>
                </Box>
                <Link sx={{display: 'flex', alignItems: 'center', textDecoration: 'none', color: 'unset', cursor: 'pointer'}} underline="none">
                    <React.Fragment>
                        <Box sx={{display: 'flex', alignItems: 'center', textAlign: 'center'}}>
                            <Box
                                onClick={handleClick}
                                // size="small"
                                sx={{display: 'flex', alignItems: 'center', cursor: 'pointer'}}

                                aria-controls={open ? 'account-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}
                            >
                                <Avatar sx={{width: 24, height: 24, backgroundColor: '#c7c7c7'}} ><PersonIcon sx={{color: '#000000'}}/></Avatar>
                    <Typography sx={{paddingLeft: '8px'}}>{userName}</Typography>
                            </Box>
                        </Box>
                        <Menu
                            anchorEl={anchorEl}
                            id="account-menu"
                            open={open}
                            sx={{cursor: 'pointer'}}
                            onClose={handleClose}
                            onClick={handleClose}
                            transformOrigin={{horizontal: 'right', vertical: 'top'}}
                            anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
                        >
                            <MenuItem onClick={editProfile}>
                                <ListItemIcon>
                                    <Edit fontSize="small"/>
                                </ListItemIcon>
                                Profiel bewerken
                            </MenuItem>
                            <MenuItem onClick={logoff}>
                                <ListItemIcon>
                                    <Logout fontSize="small"/>
                                </ListItemIcon>
                                Uitloggen
                            </MenuItem>
                        </Menu>
                    </React.Fragment>
                </Link>
            </Box>
            <Box sx={{marginTop: '16px'}}>
                <img src="/header.png" style={{objectFit: 'contain'}}/>

            </Box>
        </Box>
    );
};

const TopRow = styled.div`
    background: linear-gradient(90deg, #ff9300, #aa418c);
    height: 8px;
`;
const NavRow = styled.nav`
    display: flex;
    align-items: center;
    background: #efefef;
    justify-content: flex-end;
    padding-right: 40px;
`;
