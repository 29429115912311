import React from 'react';

export interface EuropeLogoProps {
  height: number;
  width: number;
}


export const EuropeLogo: React.FC<EuropeLogoProps> = (props) => {
  return <svg width={props.width} height={props.height} viewBox="0 0 55 55" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_657_25411)">
      <path
          d="M5.85637 35.3442L4.46096 36.1499L4.46085 36.15C5.34934 37.6888 4.82007 39.6637 3.28125 40.552L4.08691 41.9475C4.82855 41.5195 5.69642 41.4068 6.53098 41.6303C7.36543 41.854 8.06088 42.3855 8.48906 43.1272L9.88458 42.3215C9.4564 41.58 9.34371 40.7119 9.56736 39.8773C9.79102 39.0428 10.3225 38.3475 11.0642 37.9192L10.2585 36.5237C8.71948 37.4121 6.74485 36.8831 5.85637 35.3442ZM8.01104 39.4604C7.92639 39.7764 7.87396 40.0957 7.85334 40.4144C7.56695 40.2731 7.26424 40.1588 6.9482 40.074C6.6326 39.9895 6.31377 39.9372 5.99537 39.9163C6.28294 39.337 6.45245 38.7044 6.49327 38.0589C7.07271 38.3462 7.70542 38.5158 8.35092 38.5564C8.20966 38.8424 8.09568 39.1447 8.01104 39.4604Z"
          fill="#313A89"/>
      <path
          d="M50.2217 38.5941C49.9981 37.7595 50.1108 36.8915 50.5389 36.15L49.1434 35.3442C48.2549 36.8831 46.2801 37.4123 44.7413 36.5237L43.9355 37.9194C45.4744 38.8078 46.0035 40.7827 45.115 42.3215L46.5104 43.1272H46.5106C47.399 41.5885 49.374 41.0595 50.9127 41.9477L51.7184 40.5523V40.5522C50.9769 40.124 50.4453 39.4286 50.2217 38.5941ZM47.1476 40.4127C47.1067 39.7677 46.9373 39.1353 46.6501 38.5563C47.2955 38.5154 47.9283 38.3458 48.5075 38.0583C48.5283 38.3766 48.5807 38.6956 48.6652 39.0111C48.7499 39.3267 48.864 39.6291 49.0051 39.9151C48.3596 39.9559 47.727 40.1253 47.1476 40.4127Z"
          fill="#313A89"/>
      <path
          d="M44.7412 18.4761C46.28 17.5878 48.2549 18.1168 49.1433 19.6556L50.5387 18.8499L50.5388 18.8498C50.1107 18.1083 49.998 17.2403 50.2216 16.4058C50.4452 15.5712 50.9767 14.876 51.7184 14.4477L50.9127 13.0522C49.374 13.9406 47.399 13.4114 46.5106 11.8726L45.1151 12.6782L45.115 12.6783C46.0035 14.2171 45.4744 16.192 43.9355 17.0805L44.7412 18.4761ZM47.1475 14.5873C47.7269 14.8747 48.3595 15.0441 49.005 15.0847C48.8638 15.3709 48.7498 15.6733 48.6651 15.9889C48.5806 16.3045 48.5283 16.6232 48.5074 16.9416C47.9281 16.654 47.2955 16.4845 46.65 16.4436C46.9372 15.8647 47.1066 15.2324 47.1475 14.5873Z"
          fill="#313A89"/>
      <path
          d="M51.7775 23.4717H50.166C50.166 25.2485 48.7203 26.6942 46.9434 26.6942V28.3058C48.7203 28.3058 50.166 29.7515 50.166 31.5283H51.7775C51.7775 29.7515 53.2231 28.3058 55.0001 28.3058V26.6942C53.2231 26.6942 51.7775 25.2485 51.7775 23.4717ZM50.9718 28.8589C50.6139 28.3207 50.1509 27.8578 49.6128 27.5C50.151 27.1422 50.6139 26.6792 50.9718 26.1411C51.3296 26.6793 51.7926 27.1422 52.3307 27.5C51.7925 27.8578 51.3296 28.3208 50.9718 28.8589Z"
          fill="#313A89"/>
      <path
          d="M42.3209 45.115C40.7818 46.0035 38.807 45.4743 37.9188 43.9355L36.5234 44.7412L36.5232 44.7413C37.4117 46.2801 36.8826 48.2549 35.3438 49.1434L36.1494 50.5388L36.1495 50.5389C36.8911 50.1107 37.7591 49.998 38.5936 50.2217C39.4281 50.4453 40.1234 50.9768 40.5517 51.7185L41.9472 50.9128C41.0588 49.3739 41.588 47.399 43.1268 46.5107L42.3209 45.115ZM39.9147 49.0051C39.6285 48.864 39.3261 48.7499 39.0105 48.6652C38.6949 48.5807 38.3762 48.5284 38.0578 48.5075C38.3453 47.9282 38.5148 47.2956 38.5557 46.6501C39.1349 46.9373 39.767 47.1067 40.4121 47.1476C40.1247 47.727 39.9553 48.3597 39.9147 49.0051Z"
          fill="#313A89"/>
      <path
          d="M18.1593 47.1854C17.9356 46.3508 18.0483 45.4829 18.4765 44.7413L17.0811 43.9357L17.0809 43.9355C16.6527 44.6771 15.9573 45.2087 15.1228 45.4324C14.2883 45.6559 13.4202 45.5433 12.6787 45.1151L11.873 46.5106C13.4119 47.399 13.941 49.3739 13.0525 50.9128L14.4479 51.7185C15.3364 50.1798 17.3112 49.6504 18.8501 50.539L19.6558 49.1436V49.1435C18.9144 48.7152 18.3828 48.0198 18.1593 47.1854ZM15.0851 49.004C15.0442 48.3584 14.8746 47.7258 14.5871 47.1465C14.9054 47.1258 15.2244 47.0733 15.5399 46.9888C15.8559 46.904 16.1586 46.7897 16.445 46.6484C16.4657 46.9671 16.5181 47.2864 16.6027 47.6024C16.6874 47.918 16.8013 48.2204 16.9426 48.5065C16.2971 48.5472 15.6644 48.7166 15.0851 49.004Z"
          fill="#313A89"/>
      <path
          d="M8.05664 28.3058V26.6942C6.27967 26.6942 4.83398 25.2485 4.83398 23.4717H3.22266C3.22266 25.2485 1.77697 26.6942 0 26.6942V28.3058C1.77697 28.3058 3.22266 29.7515 3.22266 31.5283H4.83409C4.83409 29.7515 6.27978 28.3058 8.05664 28.3058ZM4.02832 28.8589C3.6705 28.3207 3.20751 27.8578 2.66933 27.5C3.20751 27.1422 3.6705 26.6792 4.02832 26.1411C4.38614 26.6793 4.84913 27.1422 5.38731 27.5C4.84913 27.8578 4.38614 28.3208 4.02832 28.8589Z"
          fill="#313A89"/>
      <path
          d="M4.46096 18.8498L5.85647 19.6556C6.74496 18.1168 8.7197 17.5874 10.2586 18.4761L11.0643 17.0807V17.0806C10.3226 16.6524 9.79112 15.9571 9.56747 15.1225C9.34382 14.2879 9.4565 13.4199 9.88469 12.6783L8.48917 11.8726C7.60079 13.4114 5.62605 13.9405 4.08702 13.0522L3.28125 14.4477C4.82018 15.3362 5.34944 17.3109 4.46096 18.8498ZM7.85345 14.5865C7.87418 14.9049 7.9266 15.2238 8.01114 15.5394C8.09579 15.855 8.20977 16.1574 8.35103 16.4435C7.70553 16.4842 7.07281 16.6536 6.49338 16.941C6.45256 16.296 6.28315 15.6635 5.99602 15.0846C6.6413 15.0437 7.27434 14.874 7.85345 14.5865Z"
          fill="#313A89"/>
      <path
          d="M26.6934 8.05675H28.3048C28.3048 6.27978 29.7504 4.83409 31.5273 4.83409V3.22266C29.7504 3.22266 28.3048 1.77697 28.3048 0H26.6934C26.6934 1.77697 25.2477 3.22266 23.4707 3.22266V4.83409C25.2478 4.83409 26.6934 6.27978 26.6934 8.05675ZM27.4991 2.66943C27.857 3.20772 28.3198 3.6705 28.858 4.02832C28.3197 4.38625 27.857 4.84913 27.4991 5.38731C27.1413 4.84902 26.6784 4.38625 26.1402 4.02832C26.6784 3.6705 27.1413 3.20762 27.4991 2.66943Z"
          fill="#313A89"/>
      <path
          d="M15.1227 9.56785C15.9572 9.7915 16.6526 10.323 17.0808 11.0647L18.4762 10.259L18.4763 10.2589C18.0481 9.51736 17.9354 8.64939 18.1591 7.81483C18.3827 6.98027 18.9142 6.28504 19.6559 5.85675L18.8502 4.46123C17.3113 5.34972 15.3365 4.82056 14.4481 3.28174L13.0525 4.0874C13.941 5.62633 13.4119 7.60117 11.873 8.48955L12.6787 9.88507C13.4202 9.45688 14.2883 9.34441 15.1227 9.56785ZM15.0849 5.99629C15.6643 6.28375 16.297 6.45315 16.9424 6.49376C16.8012 6.77982 16.6872 7.08222 16.6025 7.39782C16.5179 7.71375 16.4654 8.03312 16.4448 8.35184C16.1584 8.21047 15.8557 8.09617 15.5397 8.01142C15.2241 7.92688 14.9052 7.87456 14.5868 7.85372C14.8744 7.2745 15.0439 6.64179 15.0849 5.99629Z"
          fill="#313A89"/>
      <path
          d="M36.5233 10.2588L37.9189 11.0646C38.8074 9.52585 40.7819 8.99626 42.321 9.88507L43.1268 8.48955C41.588 7.60106 41.0588 5.62633 41.9472 4.0874L40.5518 3.28174H40.5517C40.1235 4.02327 39.4281 4.5549 38.5936 4.77855C37.7589 5.00199 36.8911 4.88941 36.1495 4.46134L35.3438 5.85686C36.8826 6.74523 37.4117 8.71997 36.5233 10.2588ZM39.0106 6.33488C39.3262 6.25023 39.6286 6.13615 39.9148 5.995C39.9554 6.6405 40.1248 7.27311 40.4122 7.85254C39.7671 7.89336 39.1349 8.06276 38.5559 8.35001C38.5149 7.70451 38.3454 7.0719 38.0579 6.49258C38.3762 6.47174 38.6951 6.41942 39.0106 6.33488Z"
          fill="#313A89"/>
      <path
          d="M28.3067 46.9434H26.6953C26.6953 48.7203 25.2496 50.166 23.4727 50.166V51.7775C25.2496 51.7775 26.6953 53.2231 26.6953 55.0001H28.3067C28.3067 53.2231 29.7523 51.7775 31.5293 51.7775V50.166C29.7523 50.166 28.3067 48.7203 28.3067 46.9434ZM27.501 52.3307C27.1432 51.7924 26.6803 51.3296 26.1421 50.9718C26.6803 50.6139 27.1432 50.151 27.501 49.6128C27.8588 50.1511 28.3217 50.6139 28.8599 50.9718C28.3216 51.3296 27.8588 51.7925 27.501 52.3307Z"
          fill="#313A89"/>
      <path d="M29.918 17.0264H31.5293V18.6377H29.918V17.0264Z" fill="#313A89"/>
      <path d="M29.918 20.249H31.5293V21.8604H29.918V20.249Z" fill="#313A89"/>
      <path
          d="M40.0659 38.8296C42.8736 35.7178 44.4199 31.6942 44.4199 27.5C44.4199 18.1708 36.8301 10.5811 27.501 10.5811C22.4562 10.5811 17.7116 12.8087 14.4837 16.6927L15.7229 17.7227C17.4881 15.5987 19.7548 14.0234 22.2793 13.1083C21.6025 13.8316 20.974 14.7022 20.4076 15.7121C18.7418 18.6818 17.7793 22.5557 17.6685 26.6943H12.2168C12.3602 23.9361 13.2359 21.2873 14.7759 18.9894L13.4374 18.0922C11.5695 20.8795 10.582 24.1326 10.582 27.5C10.582 36.8292 18.1718 44.4189 27.501 44.4189C31.6949 44.4189 35.7185 42.8726 38.8305 40.0649L37.7511 38.8686C36.2709 40.204 34.5625 41.2236 32.7236 41.8908C33.4 41.1676 34.0283 40.2974 34.5944 39.288C36.26 36.3183 37.2225 32.4443 37.3334 28.3058H42.7868C42.6034 31.8075 41.2294 35.135 38.8696 37.7502L40.0659 38.8296ZM42.7874 26.6943H37.3334C37.2227 22.5557 36.2602 18.6818 34.5944 15.7121C34.0286 14.7034 33.4009 13.8337 32.725 13.1108C38.3608 15.1632 42.4614 20.4374 42.7874 26.6943ZM28.3066 12.2675C30.1208 12.6052 31.8305 14.0782 33.189 16.5002C34.7234 19.2359 35.6127 22.8303 35.7214 26.6942H28.3066V12.2675ZM21.813 16.5003C23.1716 14.0783 24.8812 12.6053 26.6953 12.2676V26.6943H19.2805C19.3892 22.8304 20.2786 19.236 21.813 16.5003ZM26.6953 42.7325C24.8812 42.3948 23.1714 40.9218 21.813 38.4998C20.2786 35.7641 19.3892 32.1697 19.2805 28.3058H26.6953V42.7325ZM12.2145 28.3057H17.6685C17.7793 32.4443 18.7418 36.3182 20.4076 39.2879C20.9734 40.2966 21.601 41.1663 22.2769 41.8892C16.6412 39.8368 12.5405 34.5626 12.2145 28.3057ZM33.189 38.4997C31.8304 40.9217 30.1208 42.3947 28.3066 42.7324V28.3057H35.7214C35.6127 32.1696 34.7234 35.764 33.189 38.4997Z"
          fill="#313A89"/>
    </g>
    <defs>
      <clipPath id="clip0_657_25411">
        <rect width="55" height="55" fill="white"/>
      </clipPath>
    </defs>
  </svg>

};
