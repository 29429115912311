import React, { PropsWithChildren } from 'react';
import { SxProps } from '@mui/system';
import { Card, CardContent, Theme, Typography } from '@mui/material';

export interface Props {
  title: string;
  sx?: SxProps<Theme>;
}

export const OCard: React.FC<Props & PropsWithChildren> = (props) => {
  return (
      <Card variant="outlined" sx={Object.assign({borderColor: '#313A89', borderRadius: '8px'}, props.sx)}>
      <CardContent>
          <Typography sx={{fontSize: '14px', fontWeight: 600, color: '#1c1c1c', marginBottom: '16px'}}>{props.title}</Typography>
        {props.children}
      </CardContent>
    </Card>
  );
};

